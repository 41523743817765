module.exports = {
  // 网关代理地址
  EXAM_USER: '/exam-user', // 正式地址
  EXAM_MASTER: '/exam-mark',
  EXAM_SCORE: '/exam-score',
  EXAM_DRAW: '/exam-draw-lots',
  // 网关代理地址
  QCKJ_URL: '/api',

  // 跳转路径
  PATH_SCORE: '/score',
  PATH_HOME: '/product',
  PATH_USER: '/user',
  PATH_MARK: '/mark'
}
