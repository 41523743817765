import Cookies from 'js-cookie'

const TokenKey = 'Admin-Token'

export function getToken (cookieKey) {
  if (cookieKey) {
    return Cookies.get(cookieKey)
  }
  return Cookies.get(TokenKey)
}

export function setToken (token, cookieKey) {
  if (cookieKey) {
    return Cookies.set(cookieKey, token)
  }
  return Cookies.set(TokenKey, token)
}
export function removeToken () {
  Cookies.remove('token')
  return Cookies.remove('token')
}

export function setCookies (cookieKey, token) {
  return Cookies.set(cookieKey, token)
}
export function getCookies (cookieKey) {
  return Cookies.get(cookieKey)
}
export function removeCookies (cookieKey) {
  return Cookies.remove(cookieKey)
}
