<template>
  <div class="content">
    <!-- banner -->
    <div class="banner">
      <!-- <el-carousel trigger="click" :height="height+'px'" :interval='5000' loop>
        <el-carousel-item v-for="item in 3" :key="item">
          <img :ref="'imgs'+item" src="../../../assets/masterStation/case.jpg" alt="柒彩科技" @load="imgload">
        </el-carousel-item>
      </el-carousel>
      <div class="banner_txt">
        <h3>连接科技与教育,让教学更成功</h3>
        <h4>OICAI &emsp;SMART &emsp; CAMPUS</h4>
        <div>
          <span style="background-color:#1F5BB3">产品服务</span>
          <span>联系我们</span>
        </div>
      </div> -->
    </div>
    <!-- ad展示图片 -->
    <div class="adimg box">
      <img src="../../../assets/masterStation/1_03.png" alt="柒彩科技">
    </div>

    <div class="box">
      <!-- 智慧教学 -->
      <div class="list ">
        <dl>
          <dt>
            <div> <b>智慧教学</b> <span>99</span></div> <b>···</b>
          </dt>
          <div class="itemBox">
            <dd v-for="(item,index) in wisdomlist" :key='index' class="item" @click="topath(item)">
              <img :src="require('../../../assets/masterStation/'+item.icon)" :alt="item.txt">
              <div class="item_txt">
                <h3>{{item.txt}}</h3>
                <div>
                  <el-rate v-model="value" disabled> </el-rate>
                  <span>共使用{{index+1766}}次</span>
                </div>
                <p> {{item.text}}</p>
              </div>
            </dd>
          </div>
        </dl>
      </div>

      <!-- 智慧学习 -->
      <div class="list ">
        <dl>
          <dt>
            <div> <b>智慧学习</b> <span>8</span></div> <b>···</b>
          </dt>
          <div class="itemBox">
            <template v-for="(item,index) in XueXi">
              <dd class="item" :key="index" >
                <img :src="require('../../../assets/masterStation/'+item.icon)" :alt="item.txt">
                <div class="item_txt">
                  <h3>{{item.txt}}</h3>
                  <div>
                    <el-rate v-model="value" disabled> </el-rate>
                    <span>共使用1766次</span>
                  </div>
                  <p> 支持校级,区县级及灵活联考</p>
                </div>
              </dd>
            </template>
          </div>
        </dl>
      </div>

      <!-- 智慧管理 -->
      <div class="list ">
        <dl>
          <dt>
            <div> <b>智慧管理</b> <span>18</span></div> <b>···</b>
          </dt>
          <div class="itemBox">
            <dd v-for="(item,index) in Guanli" :key='index' class="item">
              <img :src="require('../../../assets/masterStation/'+item.icon)" :alt="item.txt">
              <div class="item_txt">
                <h3>{{item.txt}}</h3>
                <div>
                  <el-rate v-model="value" disabled> </el-rate>
                  <span>共使用1766次</span>
                </div>
                <p> 支持校级,区县级及灵活联考</p>
              </div>
            </dd>
          </div>
        </dl>
      </div>

      <!-- 其它应用 -->
      <div class="list " v-if="otherList.length>0">
        <dl>
          <dt>
            <div> <b>其它应用</b> <span>3</span></div> <b>···</b>
          </dt>
          <div class="itemBox">
            <template v-for="(item,index) in otherList">
              <dd class="item" :key="index" >
                <img :src="require('../../../assets/masterStation/'+item.icon)" :alt="item.txt">
                <div class="item_txt">
                  <h3>{{item.txt}}</h3>
                  <div>
                    <el-rate v-model="value" disabled> </el-rate>
                    <span>共使用1766次</span>
                  </div>
                  <p> 支持校级,区县级及灵活联考</p>
                </div>
              </dd>
            </template>
          </div>
        </dl>
      </div>

      <!-- 排行榜 -->
      <div class="Ranking">
        <div class="Ranking_list">
          <h3> <i class="iconfont icon-gouwuche"></i> 畅销排行</h3>
          <dl>
            <dd class="Ranking_li" v-for="(item,index) in rankingList " :key="index">
              <div>
                <span>{{index + 1}}</span>
                {{item}}
              </div>
              <p>33451 <span>次</span></p>
            </dd>
          </dl>
        </div>
        <div class="Ranking_list">
          <h3> <i class="iconfont icon-iconfonthaoping"></i> 好评排行</h3>
          <dl>
            <dd class="Ranking_li" v-for="(item,index) in rankingList " :key="index">
              <div>
                <span>{{index + 1}}</span>
                {{item}}
              </div>
              <p>33451 <span>次</span></p>
            </dd>
          </dl>
        </div>
        <div class="Ranking_list">
          <h3> <i class="iconfont icon-zonghe"></i> 综合排行</h3>
          <dl>
            <dd class="Ranking_li" v-for="(item,index) in rankingList " :key="index">
              <div>
                <span>{{index + 1}}</span>
                {{item}}
              </div>
              <p>33451 <span>次</span></p>
            </dd>
          </dl>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
import { getToken } from '@/utils/auth'
import baseUrl from '@/utils/baseUrl'
export default {
  components: {},
  data () {
    return {
      height: 860,
      value: 5,
      wisdomlist: [
        { icon: '1_09.png', txt: '用户中心', path: baseUrl.PATH_USER, text: '用户权限、学校班级教师学生管理' },
        { icon: '1_07.png', txt: '网络阅卷', path: baseUrl.PATH_MARK, text: '支持校级,区县级及灵活联考' },
        { icon: '1_11.png', txt: '成绩分析', path: baseUrl.PATH_SCORE, text: '支持教师查分，班级考试分析' },
        { icon: '1_16.png', txt: '题库云' }
      ],
      XueXi: [
        { icon: '1_09.png', txt: '人人通空间' },
        { icon: '1_07.png', txt: '一师—优课' },
        { icon: '1_11.png', txt: '名师工作室' }
        // { icon: '1_16.png', txt: '题库云' },
        // { icon: '1_17.png', txt: '成绩分析' }
        // { icon: '1_18.png', txt: '名师工作室' }
      ],
      Guanli: [
        { icon: '1_09.png', txt: '综合素质评价' },
        { icon: '1_07.png', txt: '流程审批' }
        // { icon: '1_11.png', txt: '名师工作室' },
        // { icon: '1_16.png', txt: '题库云' }
        // { icon: '1_17.png', txt: '一师—优课' }

      ],
      otherList: [],
      rankingList: [
        '网络阅卷',
        '教学助手',
        '人人通空间',
        '网络教研',
        '一师—优课',
        '名师工作室',
        '综合素质评价',
        '流程审批'
      ]
    }
  },
  mounted () {},
  methods: {
    topath (item) {
      if (item.path) {
        // 判断是否是成绩分析跳转参数  是则带上token跳回去
        if (getToken() && item.txt === '成绩分析') {
          window.open(item.path + '?xxl_sso_sessionid=' + getToken())
          return
        }
        window.open(item.path)
      }
    }
  }
}
</script>
<style lang="less" scoped>
.content {
  background-color: #f9f9f9;
  overflow: hidden;
}

.banner {
  position: relative;
  height: 70px;
  background: #000;
    &::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 70%;
    z-index: 3;
    background-color: rgba(0, 0, 0, 0.3);
  }
  img {
    width: 100%;
    display: block;
  }
  .banner_txt {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    text-align: center;
    z-index: 10;
    h3 {
      margin-top: 0;
      font-size: 32px;
      margin-bottom: 20px;
    }
    h4 {
      font-size: 16px;
      font-weight: 500;
    }
    div {
      margin-top: 50px;
    }
    span {
      display: inline-block;
      width: 120px;
      height: 40px;
      line-height: 40px;
      border-radius: 5px;
      font-size: 16px;
      margin: 0 15px;
      background-color: #fd6702;
      cursor: pointer;
    }
  }
  /deep/ .is-active {
    button {
      background-color: #365685;
    }
  }
}
.adimg {
  img {
    width: 100%;
  }
}
.list {
  background-color: #fff;
  margin-top: 35px;
  overflow: hidden;
  dl {
    margin: 0;
  }
  dt {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 30px;
    font-size: 20px;
    color: #89a3ec;
    height: 45px;
    border-bottom: 1px solid #f2f2f2;
    div {
      display: flex;
      align-items: center;
      height: 100%;
      b {
        position: relative;
        height: 100%;
        line-height: 45px;
        &::after {
          content: "";
          display: block;
          width: 100%;
          height: 1px;
          background-color: #365685;
          position: absolute;
          bottom: 0;
        }
      }
      span {
        display: block;
        font-size: 12px;
        background-color: #c3c3c3;
        color: #fff;
        margin-left: 25px;
        border-radius: 15px;
        padding: 2px 10px;
      }
    }
  }
  dt > b {
    cursor: pointer;
  }
  .item {
    display: inline-flex;
    box-sizing: border-box;
    padding: 30px 45px;
    width: calc(100% / 3);
    border: 1px solid #f2f2f2;
    margin-top: -1px;
    margin-left: -1px;
    align-items: center;
    cursor: pointer;
    &:hover{
      box-shadow: 0 0px 8px  #B1B5FF inset;
    }

    &:nth-child(3n) {
      border-right: none;
    }
    &:last-child {
      border-right: none;
    }
    &:nth-child(3n + 1) {
      padding-left: 41px;
    }
    img {
      width: 65px;
      height: 65px;
      border-radius: 5px;
      margin-right: 15px;
    }
    h3 {
      margin: 0 0 2px;
      color: #191919;
      font-size: 18px;
    }
    .item_txt {
      div {
        display: flex;
        align-items: center;
        margin-bottom: 2px;

        /deep/.el-rate__icon {
          margin-right: 0;
        }
        span {
          margin-left: 15px;
          font-size: 12px;
          color: #cacaca;
        }
      }
      p {
        font-size: 12px;
        color: #757376;
      }
    }
  }
}
.Ranking {
  margin: 50px 0;
  display: flex;
  justify-content: space-around;
  .Ranking_list {
    width: 30%;
    h3 {
      color: #6a6a6a;
      font-size: 30px;
      font-weight: 500;
      margin: 0 0 20px;
      i {
        font-size: 30px;
        margin-right: 10px;
        color: #C3C3C3;
      }
    }
    .Ranking_li{
      width: 300px;
      height: 40px;
      box-sizing: border-box;
      padding: 0 15px 0 0;
      box-shadow: 0 0 10px #c3c3c3;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0 0 15px;
      border-radius: 5px;
      div{
        display: flex;
        align-items: center;
        font-size: 18px;
        color: #6D6D6D;
        span{
          display:block ;
          height: 20px;
          line-height: 20px;
          width: 35px;
          text-align: center;
          color: #fff;
          background-color: #C4F0FD ;
          border-radius: 0 10px 10px 0;
          margin-right: 15px;
        }
      }
      &:nth-child(1){
        div{
          color: #FEC23F;
          span{
            background-color: #FEC23F ;
          }
        }
      }
      &:nth-child(2){
        div{
          color: #F89293 ;
          span{
            background-color: #F89293 ;
          }
        }
      }
      &:nth-child(3){
        div{
          color: #c99979;
          span{
            background-color: #c99979 ;
          }
        }
      }
      p{
        color: #1993D7 ;
        font-size: 16px;
        span{
          color: #C3C3C3;
        }
      }
    }
  }
}
</style>
